<template>
    <a-card :bordered="false">
      <div class="">
        <a-form layout="inline" label-align="left">
          <a-row :gutter="48">
            <a-col :md="8">
              <a-form-item label="反馈时间">
                <a-space direction="vertical" :size="12">
                    <a-range-picker @change="opDateChange" />
                </a-space>
              </a-form-item>
            </a-col>

            <a-col :md="8">
              <a-form-item label="反馈内容">
                <a-input v-model="queryParam.content" placeholder="请输入"/>
              </a-form-item>
            </a-col>

            <a-col :md="8">
              <a-form-item label="反馈人昵称">
                <a-input v-model="queryParam.nickname" placeholder="请输入"/>
              </a-form-item>
            </a-col>
        

            <a-col :md="8">
              <a-form-item label="反馈人电话">
                <a-input v-model="queryParam.phone" placeholder="请输入"/>
              </a-form-item>
            </a-col>

            <a-col :md="8">
              <span>
                <a-button type="primary" @click="loadData">搜索</a-button>
              </span>
            </a-col>

          </a-row>
        </a-form>
      </div>

      <a-table
        size="default"
        rowKey="id"
        :columns="columns"
        :dataSource="data"
        :alert="true"
        showPagination="auto"
      >
        <span slot="img" slot-scope="text">
          <img
            :width="100"
            :height="100"
            :src="text"
          />
        </span>
        

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="showDetail(record)">详情</a>
          </template>
        </span>
      </a-table>



      <a-modal
        v-model="modelVisible"
        title="详情"
        centered
        @ok="() => (modelVisible = false)"
      >
        <p>头像: <a-avatar :src="detailRecord.avatar_url" /></p>
        <p>昵称: {{detailRecord.nickname}}</p>
        <p>发布时间: {{detailRecord.created_at}}</p>
        <p>反馈内容: {{detailRecord.content}}</p>
      </a-modal>
    </a-card>
</template>

<script>
import request from '@/utils/request'

const columns = [
  {
    title: '序号',
    dataIndex: "id"
  },
  {
    title: '反馈内容',
    dataIndex: 'content'
  },
    {
    title: '反馈人昵称',
    dataIndex: 'nickname',
  },

   {
    title: '反馈人电话',
    dataIndex: 'phone',
  },

  {
    title: '反馈时间',
    dataIndex: 'created_at',
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }
]


export default {
  name: 'User',
  components: {
  },
  data () {
    this.columns = columns
    return {
     modelVisible: false,
     detailRecord: {
      avatar_url: '',
      nickname: '',
      created_at: '',
      content: ''
     },
      data: [],
      total: 0,
      // 查询参数
      queryParam: {
        page: 1,
        limit: 20,
        created_start_at: '',
        created_end_at: '',
        nickname: '',
        content: '',
        phone: '',
      }
    }
  },
  filters: {
  },
  created () {
    this.loadData()
  },
  computed: {
  },
  methods: {
    loadData() {
      request({
        url: "/feedback/list",
        method: 'get',
        params: this.queryParam
      }).then(res => {
        this.data = res.data.list
        this.total = res.data.total
      })
    },
    showDetail(record) {
      this.detailRecord = record
      this.modelVisible = true;
    },
    addRemark(record) {
      console.log(record)
    },
    handleStopUse(record) {
      request({
        url: "/record/op",
        method: 'post',
        data: {
          id: record.id,
          status: 1
        }
      }).then(res => {
      console.log(res)
        if (res.status == 1) {
            this.loadData()
        }
      })
    },

    handleStartUse (record) {
      request({
        url: "/record/op",
        method: 'post',
        data: {
          id: record.id,
          status: 2
        }
      }).then(res => {
        if (res.status == 1) {
            this.loadData()
        }
      })
    },

    opDateChange(data, dataString) {
      console.log(data, dataString)
      this.queryParam.created_start_at = dataString[0]
      this.queryParam.created_end_at = dataString[1]
    },


    autoApproved(checked) {
      console.log(`a-switch to ${checked}`);
    }

  }
}
</script>
